import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './Opening.css'
import hero from '../../../../Assets/Images/Home/Hero.png'


const Opening = () => {

    let navigate = useNavigate()

    const gotoServices = () => {

        navigate('/servicios')

    }



    //selectable base in page
    //mobile menu

    return(
        <div className="outter-text-container">

        <div className="text-container">    
        <div className="tittle">
            <div className="rectangle-container">
                <div className="rectangle-1"></div>
            </div>
            <div className="tittle-container">
                <h1 className="light-green"> Transparentes, </h1>
                <h1> puntuales y adaptables</h1>
            </div>
            
            <button className="button-light" onClick={gotoServices}>
            Contáctanos
            </button>
        </div>
        
        </div>
            <div className="hero-image"></div>
            <img className="hero-image-home" src={hero}></img>
        </div>
        
    )
}
export default Opening