import React from "react";

import Opening from "../../Components/Services/Segments/Opening";
import Services from "../../Components/Services/Segments/Services"
import Comunicacion from "../../Components/Services/Segments/Comunicacion";
import Afiliate from "../../Components/Services/Segments/Afiliate";
import Success from "../../Components/Services/Segments/Modal/Succes";
import Calculator from "../../Components/Utilities/Calculator";
import '../../Components/Utilities/Calculator/Calculator.css'

const Servicios = () => {
    return (
        <>
      
             <Calculator/>
            <div className="separator100"/>
            <Afiliate/>
            <div className="separator100"/>

        </>
    )
}
export default Servicios