import React from "react";
import './Services.css'
import a from '../../../../Assets/Images/Home/1.png'
import b from '../../../../Assets/Images/Home/2.png'
import c from '../../../../Assets/Images/Home/3.png'
import d from '../../../../Assets/Images/Home/4.png'
import f from '../../../../Assets/Images/Home/5.png'

import g from '../../../../Assets/Images/Home/6.png'
import h from '../../../../Assets/Images/Home/7.png'
import i from '../../../../Assets/Images/Home/8.png'

import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";


const Services = () => {
    return (
<div className="outter-text-container-centered">

        <div className="text-container-centered">    
        <div className="tittle">
            
            <div className="tittle-container-centered">
                <div className="rectangle-container">
                    <div className="rectangle-1"></div>
                </div>
             
                <h1 className="light-green">Servicios</h1>
               
            </div>
            <AnimationOnScroll animateIn="animate__bounceInRight" duration={2}>
            <div className="object-container">
                <div className="card-container">
                    <img className="card-image" src ={a}>
                    </img>
                    <h3 className="centered">
                    Transporte Aéreo.
                    </h3>
                  
                </div>

                <div className="card-container">
                    <img className="card-image" src ={h}>
                    </img>
                    <h3 className="centered">
                    Transporte Marítimo.
                    </h3>
                  
                </div>

                <div className="card-container">
                    <img className="card-image" src ={g}>
                    </img>
                    <h3 className="centered">
                    Transporte Terrestre.
                    </h3>
                  
                </div>
                <div className="card-container">
                    <img className="card-image" src ={b}>
                    </img>
                    <h3 className="centered">
                        Depósito Aduanero (INBOUND). 
                    </h3>
                
                </div>


            </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__bounceInLeft"duration={2}>
            <div className="object-container">
               

      

                <div className="card-container">
                    <img className="card-image" src ={c}>
                    </img>
                    <h3 className="centered">
                    Revisión de Mercancía. 
                    </h3>
                   
                </div>

                <div className="card-container">
                    <img className="card-image" src ={d}>
                    </img>
                    <h3 className="centered">
                        Asesoría Aduanal.
                    </h3>
                   
                </div>

                <div className="card-container">
                    <img className="card-image" src ={f}>
                    </img>
                    <h3 className="centered">
                    Gestionadores de Pagos.
                    </h3>
                   
                </div>

                <div className="card-container">
                    <img className="card-image" src ={i}>
                    </img>
                    <h3 className="centered">
                    Casillero.
                    </h3>
                   
                </div>


            </div>
            </AnimationOnScroll>
           
        </div>
        </div>

        </div>
    )
}
export default Services