import React from "react";
import ig from '../../../../Assets/social/Instagram - Negative.svg'
import tw from '../../../../Assets/social/Twitter - Negative.svg'
import li from '../../../../Assets/social/LinkedIn - Negative.svg'
import img from '../../../../Assets/logo.svg'
import './Locations.css'

const Location = () =>  {

    const goIg = () => {
        window.open('https://www.instagram.com/europeanlogisticsforwarder/?igshid=YjNmNGQ3MDY%3D')
    }
    const goTw = () => {
        window.open('https://twitter.com/envios_elf')

    }

    const goLi= () => {
        window.open('https://www.linkedin.com/company/european-logistics-forwarder-elf/?original_referer=');
 
    }

    return (
    
            <div className="location-outter">

                <div className="location-details">

                    
                <div className="location-outter2">

                  

                    <div className="location">
                      
                      <div className="separator50"></div>
                      <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.8311467316007!2d2.137215868618109!3d41.39947321272758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a49874ee2b7e8f%3A0xa785d9e8d558978!2sCarrer%20De%20Descartes%2C%2020%2C%2008021%20Barcelona%2C%20Spain!5e0!3m2!1sen!2sus!4v1681186368505!5m2!1sen!2sus"
                      ></iframe>
                      <div className="separator50"></div>
                      <h3 className="centered">BCN: Carrer De Descartes, 20, 08021. España</h3>

                      
                  </div>
                  <div className="location2">
                    <img className="location-img" src={img}>
                        </img>
                            <div className="social-media-container">
                            <button className="social-media-button" onClick={goTw} >
                                <img src={tw}></img>
                            </button>
                            <button className="social-media-button" onClick={goIg}>
                                <img src={ig}></img>
                            </button>
                            <button className="social-media-button" onClick={goLi}>
                                <img src={li}></img>
                            </button>
            
                        </div>
                       
                            <p className="bold">+34 603.28.73.06</p>
                            <p className="bold">+34 932.44.98.20</p>
      


                    </div>

                  <div className="location">
                     
                      <div className="separator50"></div>
                      <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.468381286686!2d-3.6712734487130856!3d40.44276916187751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4228ce4453b12f%3A0xa3c0d76ed01a7435!2sCalle%20del%20Cardenal%20Sil%C3%ADceo%2C%2035%2C%2028002%20Madrid%2C%20Spain!5e0!3m2!1sen!2sus!4v1681193714492!5m2!1sen!2sus" ></iframe>
                      <div className="separator50"></div>
                      <h3 className="centered">MAD: Calle del Cardenal Silíceo, 35, 28002. España</h3>

                    <div>
                        
                    </div>
                    
                      
                  </div>
                 
            
                   

                </div>
              
        
                </div>

               

          
            </div>

         


    )
}

export default Location;