import React from "react";
import { useNavigate } from "react-router-dom";
import logo from '../../../Assets/logo.svg'
import './Construction.css'

const Contruction = () => {
    let navigate = useNavigate()

   const goToAfiliate = () =>{
        navigate('/servicios')

    }
    return(
        <div className="uConstruction">
            <img src = {logo} className="uConstruction-logo"></img>
            <h1 className="centered">Bajo Construcción</h1>
            <h3 className="centered">Esta página aún no está disponible si tienes alguna pregunta puedes contactarnos aquí:</h3>
            <div className="separator50"></div>
            <button className="button-light" onClick={goToAfiliate}>Contáctanos</button>
        </div>
    )
}

export default Contruction