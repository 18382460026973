import React, { useState }  from "react";
import Location from "../../Components/Home/Segments/Location";
import Nosotros from "../../Components/Home/Segments/Nosotros";
import Opening from "../../Components/Home/Segments/Opening";
import Services from "../../Components/Home/Segments/Services";

import '../../Components/Utilities/Calculator/Calculator.css'



import { AnimationOnScroll } from 'react-animation-on-scroll';
import Calculator from "../../Components/Utilities/Calculator";

const Home = () => {
    
        

   
    return (

            <>  
            <Calculator/>
           
            <Opening/>
            <div className="separator100"/>
            <div className="separator100"/>
            <Services/>
            <div className="separator100"/>
            <div className="separator100"/>
            <Nosotros/>
            <div className="separator100"/>
            <div className="separator100"/>
            <Location/>
            <div className="separator100"/>
        </>
    )
}
export default Home