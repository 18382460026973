import React, { useState } from "react";
import logo from '../../../Assets/logo-white.svg'
import './Calculator.css'

import calculator from '../../../Assets/Images/Calculator/calculator.png'
import close from '../../../Assets/Images/Calculator/close.png'

import es from '../../../Assets/Images/Flags/es.svg'
import ve from '../../../Assets/Images/Flags/ve.svg'
import arrows from '../../../Assets/Images/Flags/arrows.svg'

const Calculator = () =>  {
    const [a, setA] = useState('button-selector-selected')
    const [b, setB] = useState('button-selector')

    //maritima
    
    const [Largo, setLargo] = useState()
    const [Alto, setAlto] = useState()
    const [Ancho, setAncho] = useState()
    const [Peso, setPeso] = useState()
    const [error, setError] = useState('hidden')
    const [pesoButton, setPesoButton] = useState('hidden')
    const [result, setResult] =useState(0)
    //0 - maritima 1- aereo/volumetrico 2- aereopeso

    const [tarifa, setTarifa]= useState(0)

    const [ aclara, setAclara] = useState('hidden')

    const [ buttonImg, setButtonImg] = useState(calculator)
    const[modal, setModal] = useState(1)

    const openModal = () => {
        if (modal === 1){
            setModal(0)

            setLargo()
            setAncho()
            setAlto()
            setPeso()

            setButtonImg(close)

        }else{
            setModal(1)
                        
            setLargo()
            setAncho()
            setAlto()
            setPeso()
            setResult(0)

            setTarifa(0)
            setAclara('hidden')

            setPesoButton('hidden')
            setA('button-selector-selected')
            setB('button-selector')
            setError('hidden')
         
            setButtonImg(calculator)
        }
    }

    const aereo = () => {
        setA('button-selector')
        setB('button-selector-selected')
        setAclara('centered')

                    
        setLargo()
        setAncho()
        setAlto()
        setPeso()
        setResult(0)

        setTarifa(1)
        setPesoButton("input-container")
        
        setError('hidden')
        
    }

    const mar = () => {
        setA('button-selector-selected')
        setB('button-selector')
        setAclara('hidden')

                    
        setLargo()
        setAncho()
        setAlto()
        setPeso()
        setResult(0)

        setTarifa(0)
        setPesoButton("hidden")

        setError('hidden')
         
        


    }

    const estimar = () => {

        if (Ancho > 0 || Largo > 0|| Alto > 0){

            if (tarifa=== 0){

           
    
                const firstO = (((Largo*Ancho*Alto)/1000000)*35.315)*21
    
                if(firstO <= 21){
                    setResult(21)
                    setError('hidden')
                }else{
                    setResult(firstO.toFixed(2))
                    
                    setError('hidden')
    
                }
               
             }
    
             if (tarifa=== 1){
                
    
                const volumetric = (((Largo*Ancho*Alto)/1000000)*167)
                
                if(Peso <= volumetric){
                    var firstO = volumetric*12;
                    
                    setResult(firstO.toFixed(2))

                    setError('hidden')
    
    
                }else{
    
                    var firstO = Peso*12;
                    
                    setResult(firstO.toFixed(2))

                    setError('hidden')


                    
                }
                
             }

        }
        else{
            setError('error')
            setResult(0)
        }
       

    }
    
    return(
     <>
        <button className="calculator-button" onClick={openModal}><img className="Calculator-image" src={buttonImg}></img></button>

        {modal === 0 && 
         
        <div className="calculator-outter-wrapper">
            <div className="calculator-wrapper">
                <div className="calculator-logo-wrapper">
                   
                    <img className="calculator-flag" src={es}></img>
                    <img className="calculator-arrows" src={arrows}></img>
                    <img className="calculator-flag" src={ve}></img>
                    
                </div>
                <div className="calculator-bottom-wrapper">
                    <div className="calculator-selector-wrapper">
                    
                            <p className="centered-bold">
                                Seleccione Tarifa:
                            </p>
                            <div className="separator25"></div>
                            
                            <div className="calculator-selector-buttons-wrapper">
                                <button className={a} onClick={mar}>Marítima</button>
                                <button className={b} onClick={aereo}>Aérea</button>
                            </div>
                            <div className="separator25"></div>
                             <p className={aclara}>*Se aplicará  peso que resulte mayor entre el  real o el volumétrico calculado según standares IATA.</p>
                          
                    </div>

                    <div className="calculator-input-wrappers">
                        <div className="calculator-column">
                            <div  className="input-container">
                                <h3>Largo (cm) </h3>
                                <input placeholder="0" className="calculator-form-input" value={Largo} onChange={(e)=> setLargo(e.target.value)}></input>
                            </div>  
                            
                            <div  className="input-container">
                                <h3>Alto (cm)</h3>
                                <input placeholder="0" className="calculator-form-input" value={Alto} onChange={(e)=> setAlto(e.target.value)}></input>
                                
                            </div>   
                        </div>

                        <div className="calculator-column">
                            <div  className="input-container">
                                <h3>Ancho (cm)</h3>
                                <input placeholder="0"  className="calculator-form-input" value={Ancho} onChange={(e)=> setAncho(e.target.value)}></input>
                            </div>  

                            <div  className={pesoButton}>
                                <h3>Peso (Kg)</h3>
                                <input placeholder="0"  className="calculator-form-input" value={Peso} onChange={(e)=> setPeso(e.target.value)}></input>
                            </div> 
                            
                           
                        </div>
                    </div>
                    <div className="calculator-result-wrapper">
                            <h1>€ {result}</h1>
                            <div className="separator50"></div>
                            <button className="button-light" onClick={estimar}>Estimar</button>
                            <div className="separator25"></div>
                            <p className={error}>Faltan datos.</p>
                
                           
                           
                    </div>
                </div>
                
            </div>
        </div>
          }
    </>
    )
}   
export default Calculator


