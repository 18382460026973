import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from '../../../Assets/logo.svg'
import './NavBar.css'
import Tracking from "../../../Pages/Tracking";

const NavBar = () => {

    //selectable base in page
    let navigate = useNavigate()

    const [home, setHome] =useState('navbar-button-pressed')
    const [Afiliate, setAfiliate] =useState('navbar-button')
    const [nosotros, setNosotros] =useState('navbar-button')
    const [Tracking, setTracking] = useState('navbar-button')

    
    useEffect (()=>{
        console.log(window.location.pathname);
        if (window.location.pathname === '/'){
            setHome('navbar-button-pressed')
            setAfiliate('navbar-button')
            setNosotros('navbar-button')
            setTracking('navbar-button')

            return
        }

        if (window.location.pathname === '/privacidad'){
            setHome('navbar-button')
            setAfiliate('navbar-button')
            setNosotros('navbar-button')
            setTracking('navbar-button')

            return
        }

        if (window.location.pathname === '/servicios'){
            setHome('navbar-button')
            setAfiliate('navbar-button-pressed')
            setNosotros('navbar-button')
            setTracking('navbar-button')

            return
        }
        if (window.location.pathname === '/nosotros'){
            setHome('navbar-button')
            setAfiliate('navbar-button')
            setNosotros('navbar-button-pressed')
            setTracking('navbar-button')

            return
        }

        if (window.location.pathname === '/tracking'){
            setHome('navbar-button')
            setAfiliate('navbar-button')
            setNosotros('navbar-button')
            setTracking('navbar-button-pressed')
  
            return
        }
},[window.location.pathname])


    const goHome = () => {

        navigate('/')
        window.scrollTo(0, 0)

    }
    const gotoServices = () => {

        navigate('/servicios')
        window.scrollTo(0, 0)

    }

    const gotoNosotros = () => {

        navigate('/nosotros')
        window.scrollTo(0, 0)

    }
    const gotoTracking = () => {

        navigate('/tracking')
        window.scrollTo(0, 0)

    }

    

    //mobile menu

    return(
        <div className="navbar-container">    
            <img className = 'navbar-logo' src={Logo} onClick={goHome}></img>
            <div className="navbar-menu">
                <button className={home} onClick={goHome}>
                   Inicio
                </button>
                <button className={nosotros} onClick={gotoNosotros}>
                    Nosotros
                </button>

                <button className={Afiliate} onClick={gotoServices}>
             
                Contáctanos
                </button>
                <button className={Tracking} onClick={gotoTracking}>
             
                Tracking
                </button>


               
            </div>
        </div>
        
    )
}
export default NavBar