import React from "react"
import './Mision.css'
import mission from '../../../../Assets/Images/Nosotros/mission.png'


import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const MisionVision = () => {

    return (
        <div className="outter-wrapper-mission-container">
            <AnimationOnScroll animateIn="animate__slideInLeft" >

                <div className="outter-mission-container">

                    <div className="tittle">
                        <div className="tittle-container-justified">
                                <div className="rectangle-container">
                                    <div className="rectangle-1"></div>
                                </div>
                            
                                <div className="tittle-container">
                                <h1> Misión y </h1> <h1 className="light-green">Visión</h1>
                                </div>
                                
                            </div>

                            

                    </div>
                
                    
                    <div className="text-mission-container-centered">    
                        
                        
                        <div>
                            <div className="object-mision-container">
            
                            
                            

                            

                            
                                <div className="card-steps-services-green">
                                    <h2 className="white"> Misión</h2>
                                    <p className="white">
                                    Proporcionar un servicio de alta calidad en logística, almacenamiento, transporte y distribución; 
                                    a través del mejoramiento continuo de nuestros procesos, 
                                    con un equipo de trabajo eficiente, en un ambiente de honestidad y respeto humano.


                                    </p>
                    
                                </div>

                                
                                <div className="card-steps-services">
                                    <h2> Visión</h2>
                                    <p>
                                    Ser una de las empresas líderes para que pequeños y medianos empresarios del continente Americano utilicen
                                    como aliado en 
                                    los procesos de adquisición, traslado, almacenamiento y distribución de sus mercancias.


                                    </p>
                    
                                </div>

                            </div>
                        </div>
                    </div>
                    
                    
                
        
                </div>
             </AnimationOnScroll>
             <AnimationOnScroll animateIn="animate__slideInRight">
             <img  className='mission-image' src={mission}></img>
             </AnimationOnScroll>
          
        </div>
      
    )
}
export default MisionVision