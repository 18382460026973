import React from "react"
import img from '../../../../Assets/Images/Nosotros/truck.png'
const Opening = () => {

    //selectable base in page
    //mobile menu

    return(
        <div className="outter-text-container">

        <div className="text-container">    
        <div className="tittle">
            <div className="rectangle-container">
                <div className="rectangle-1"></div>
            </div>
            <div className="tittle-container">
                <h1 > ¿Quiénes somos? </h1>
         
            </div>
            <div className="p-container">
            <p>  European Logistics Forwarder (ELF) es una empresa que ofrece  servicios de logística internacional a clientes, exportadores o importadores, de manera personalizada y atendiendo a necesidades específicas.
                    Igualmente ofrece servicio de paquetería para clientes con necesidad de enviar mercancías con fines no comerciales.
            </p>

            </div>
            
 
        </div>
        
        </div>
            <img className="nosotros-image" src={img}></img>
        </div>
        
    )
}
export default Opening