import React, { useState } from "react";
import './Opening.css'
import { useNavigate } from "react-router-dom";
import map from '../../../../Assets/Images/Services/World.png'



const Opening = () => {

    //selectable base in page
    //mobile menu
    let navigate = useNavigate()

    const goAfiliate = () => {
        navigate('/servicios')
        window.scrollTo(0, 0)
    }
    return(
        <div className="outter-text-container">

        <div className="text-container">    
        <div className="tittle">
            <div className="rectangle-container">
                <div className="rectangle-1"></div>
            </div>
            <div className="tittle-container">
                <h1 > Alcance </h1>

            </div>
            <div className="p-container">
            <p>Queremos apoyar al pequeño y mediano empresario, que vea oportunidades en Europa para potenciar su negocio, 
                con la presencia de un aliado estratégico en el viejo continente.</p>
           
            </div>
            <button className='button-light' onClick={goAfiliate}>Contáctanos</button>
            
 
        </div>
        
        </div>
            <img className="hero-image-services" src={map}></img>
            <div className="hero-image"></div>
            

        </div>
        
    )
}
export default Opening