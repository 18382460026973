
import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import NavBar from './Components/Structural/NavBar';
import Home from './Pages/Home';
import Footer from './Components/Structural/Footer';
import Servicios from './Pages/Services';
import Nosotros from './Pages/Nosotros';

import ContactanosPage from './Pages/Contactanos';
import Privacidad from './Pages/Privacidad';
import Tracking from './Pages/Tracking';


function App() {
  return (
<>
    
   <BrowserRouter>
   <NavBar/>
    <Routes>
      <Route exact path='/' element = {<Home/>}/>
      <Route exact path='/servicios' element = {<Servicios/>}/>
      <Route exact path='/nosotros' element = {<Nosotros/>}/>
      <Route exact path='/tracking' element = {<Tracking/>}/>
      <Route exact path='/privacidad' element = {<Privacidad/>}/>
    </Routes>
   </BrowserRouter>
   <Footer/>


    
</>
  );
}

export default App;
