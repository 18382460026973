import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './Nosotros.css'

import img from '../../../../Assets/Images/Home/Nosotros.png'



const Nosotros = () => {
    let navigate = useNavigate()
    const gotoNosotros = () => {
     
        navigate('/nosotros')
        window.scrollTo(0, 0)

    }
    //selectable base in page
    //mobile menu

    return(
        <div className="outter-text-container">

            <div className="text-container">    
            <div className="tittle">
                <div className="rectangle-container">
                    <div className="rectangle-1"></div>
                </div>
                <div className="tittle-container">
                    <h1> ¿Por qué </h1> <h1 className="light-green">elf?</h1>
                    
                </div>
                <div className="p-container">
                <p>Porque no todos los clientes son iguales, porque Europa y América tienen que eliminar las barreras invisibles que la globalización aún no ha superado. Porque el comercio debe ser cada vez más transparente, porque la impuntualidad no debe ser normalizada.
                European Logistics Forwarder, ha sido concebida para superar los retos que plantea el comercio internacional, y quiere operar con clientes que crean en el comercio justo.</p>

                </div>
                
                <button className="button-light" onClick={gotoNosotros}>
                        ver más...
                </button>
             
            </div>
            </div>
            <img className="nosotros-image" src ={img}></img>
        
        </div>
        
    )
}
export default Nosotros