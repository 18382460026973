import React from "react";
import Opening from "../../Components/Nosotros/Segments/QuienesSomos";
import MisionVision from "../../Components/Nosotros/Segments/Misionvision";
import Contactanos from "../../Components/Nosotros/Segments/Contactanos";
import OpeningServices from "../../Components/Services/Segments/Opening";
import Calculator from "../../Components/Utilities/Calculator";
import '../../Components/Utilities/Calculator/Calculator.css'
import Location from "../../Components/Home/Segments/Location";

const Nosotros = () => {
    return (
        <>
   
            <Calculator/>
            <Opening/>
            <div className="separator100"/>
            <MisionVision/>
            <div className="separator100"/>
            <div className="separator100"/>
            <OpeningServices/>
            <div className="separator100"/>

            <Location/>
            <div className="separator100"/>
  
            

        </>
    )
}
export default Nosotros