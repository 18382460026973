import React, {useState} from "react";
import './Afiliate.css'
import Success from "../Modal/Succes";
import Logo from '../../../../Assets/logo.svg'
import { useNavigate } from "react-router-dom";
import validator from 'validator'

import emailjs from '@emailjs/browser';


const Afiliate = () => {
   

    const [nombre, setNombre] = useState('')
    const [correo, setCorreo] = useState('')
    const [pais, setPais] = useState()
    const [direccion1,setDireccion] = useState()
    const [direccion2,setDireccion2] = useState()
    const [codigoPostal,setCodigoPostal] = useState()
    const [telefono, setTelefono] = useState('')

    const [servicio1, setServicio1] =useState('No')
    const [servicio2, setServicio2] =useState('No')
    const [servicio3, setServicio3] =useState('No')
    const [servicio4, setServicio4] =useState('No')
    const [servicio5, setServicio5] =useState('No')
    const [servicio6, setServicio6] =useState('No')
    const [servicio7, setServicio7] =useState('No')
    const [servicio8, setServicio8] =useState('No')

    const [comentario, setComentario] = useState('')
    const [modal, setModal] = useState(1)

    const [a, seta] = useState('button-selector')
    const [b, setb] = useState('button-selector')
    const [c, setc] = useState('button-selector')
    const [d, setd] = useState('button-selector')
    const [e, sete] = useState('button-selector')
    const [f, setf] = useState('button-selector')
    const [g, setg] = useState('button-selector')
    const [h, seth] = useState('button-selector')

    const [terms, setTerms]=useState('radial')
    const [errorMsg, setErrorMsg]=useState('hidden')
    const [errorMsg1, setErrorMsg1]=useState('hidden')
    const [errorMsg2, setErrorMsg2]=useState('hidden')
    const [errorMsg3, setErrorMsg3]=useState('hidden')
    const [errorMsg4, setErrorMsg4]=useState('hidden')


    let navigate = useNavigate()

    const template = {
        nombre: nombre,
        correo: correo,
        pais: pais,
        direccion1: direccion1,
        direccion2: direccion2,
        codigo: codigoPostal,
        telefono: telefono,
        message: comentario,
        servicio1: servicio1,
        servicio2: servicio2,
        servicio3: servicio3,
        servicio4: servicio4,
        servicio5: servicio5,
        servicio6: servicio6,
        servicio7: servicio7,
        servicio8: servicio8
    
    };

    const validateEmail = (email) => {
        return correo.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };

    const formVerifier = () => {
        //address.match(/.+@.+/)
      
        if(nombre.length > 3){
            if(validator.isEmail(correo)) {
                if(validator.isMobilePhone(telefono)){
                    if(comentario.length > 10){
                            if(terms === 'radial-selected' ){
                                setErrorMsg1('hidden')
                                setErrorMsg2('hidden')
                                setErrorMsg3('hidden')
                                setErrorMsg4('hidden')
                                setErrorMsg('hidden')

                                AfiliateSend()
                                
                            }else{
                                setErrorMsg1('hidden')
                                setErrorMsg2('hidden')
                                setErrorMsg3('hidden')
                                setErrorMsg4('hidden')
                                setErrorMsg('error')

                            }
                    }else{
                        setErrorMsg1('hidden')
                        setErrorMsg2('hidden')
                        setErrorMsg3('hidden')
                        setErrorMsg4('error')
                        setErrorMsg('error')
                       
    

                    }
                }else{
                    setErrorMsg1('hidden')
                    setErrorMsg2('hidden')
                    setErrorMsg3('error')
                    setErrorMsg4('hidden')
                    setErrorMsg('error')

                }
        

        }else{
            setErrorMsg1('hidden')
            setErrorMsg2('error')
            setErrorMsg3('hidden')
            setErrorMsg4('hidden')
            setErrorMsg('error')


        }
        

            //  if(nombre.length > 3){
            //      setErrorMsg1('error')
            //      setErrorMsg('hidden')
            //  }

            //  if(validator.isEmail(correo)){
            //     setErrorMsg('hidden')
            //     setErrorMsg1('hidden')
            //     setErrorMsg2('error')

            //  }

        }else{
         
            // setErrorMsg('hidden')
            // AfiliateSend()

            setErrorMsg1('error')
            setErrorMsg2('hidden')
            setErrorMsg3('hidden')
            setErrorMsg4('hidden')
            setErrorMsg('error')
        }

    }

    const selectA = () => {
        if (servicio1 === 'No'){
            seta('button-selector-selected')
            setServicio1('Si')
        }
        else {
            seta('button-selector')
            setServicio1('No')
        }
       
    }


    const selectB = () => {
        if (servicio2 === 'No'){
            setb('button-selector-selected')
            setServicio2('Si')
        }
        else {
            setb('button-selector')
            setServicio2('No')
        }
       

    }


    const selectC = () => {
        if (servicio3 === 'No'){
            setc('button-selector-selected')
            setServicio3('Si')
        }
        else {
            setc('button-selector')
            setServicio3('No')
        }
       

    }

    const selectD = () => {
        if (servicio4 === 'No'){
            setd('button-selector-selected')
            setServicio5('Si')
           
        }
        else {
            setd('button-selector')
            setServicio5('No')
        }

    }

    const selectE = () => {
        if (servicio5 === 'No'){
            sete('button-selector-selected')
            setServicio5('Si')
           
        }
        else {
            sete('button-selector')
            setServicio5('No')
        }

    }
    const selectF = () => {
        if (servicio6 === 'No'){
            setf('button-selector-selected')
            setServicio6('Si')
           
        }
        else {
            setf('button-selector')
            setServicio6('No')
        }

    }

    const selectG = () => {
        if (servicio7 === 'No'){
            setg('button-selector-selected')
            setServicio7('Si')
           
        }
        else {
            setg('button-selector')
            setServicio7('No')
        }

    }

    const selectH = () => {
        if (servicio8 === 'No'){
            seth('button-selector-selected')
            setServicio8('Si')
           
        }
        else {
            seth('button-selector')
            setServicio8('No')
        }

    }

    const radial = () => {
        if (terms === 'radial'){
            setTerms('radial-selected')
        }
        else {
            setTerms('radial')
        }
        

    }

   
    const goPriv = () => {
        navigate('/privacidad')
    }
    const AfiliateSend = () => {
        //set modal on

        emailjs.send('service_fbx2swj', 'template_gzyfpyl', template, 'AEoNjHlLC6CfZLvlm')
        .then((result) => {
            console.log(result.text);
            setModal(0)
            clearAll()
        }, (error) => {
            console.log(error.text);
        });


    } 

    const clearAll = () => {
  
        setModal(0)
        setNombre('')
        setCodigoPostal('')
        setComentario('')
        setCorreo('')
        setDireccion('')
        setDireccion2('')
        setPais('')
        setTelefono('')
    


    } 

    const closeModal = () => {
        setModal(1)
    }

    // <div className="input-container">
    // <h2>Dirección:</h2>
    // <input className='form-input-space'  value = {direccion1} onChange={(e)=> setDireccion(e.target.value)}></input>
    // <input className='form-input-space'  value = {direccion2} onChange={(e)=> setDireccion2(e.target.value)}></input>
    // </div> 

    return (

        <>
        
        {modal === 0 && 
                    <div className="modal-outter">
                    <div className="modal-content">
                        <div className="separator50"></div>
                        <div className="modal-message">
                            <div className="centered">
                                <img className="modal-logo"  src = {Logo}></img>
                            </div>
                            <div className="separator50"></div>
                            <h1 className="centered"> 
                                Gracias! 
                            </h1>
                            <div className="separator50"></div>
                            <p className="centered">
                                Hemos recibido tu solicitud en menos de 48 horas te contactaremos.
                            </p>
                            <div className="separator50"></div>

                            <div className="centered">
                                <button className="button-light" onClick={closeModal}> Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
        }
      
        <div className="outter-form">
            <div className="tittle-container-centered">
                    <div className="rectangle-container">
                        <div className="rectangle-1"></div>
                    </div>
                
                    <h1 >Contáctanos</h1>
                    <p> (*) Campos Obligatorios</p>
                    
                
                </div>
                
            <div className="separator100"></div>
            <div className = 'first-half'>
                <div className="form-column">
                    <div  className="input-container">
                        <h2>*Nombre Completo:</h2>
                        <input   type='text' autoComplete="off"  pattern = "[a-zA-Z]*"   className="form-input" value = {nombre} onChange={(e)=> setNombre(e.target.value)}></input>
                        <p className={errorMsg1}>Este campo no puede estar en blanco y tiene que tener al menos 3 caracteres. </p>
                    </div>        
                    <div className="input-container" >
                        <h2>*Correo Electrónico:</h2>
                        <input autoComplete="off" className='form-input' value = {correo} onChange={(e)=> setCorreo(e.target.value)}></input>
                         <p className={errorMsg2}>Este campo no puede estar en blanco y tiene que ser un correo válido. </p>
                    </div>       
                    <div className="input-container">
                        <h2>País:</h2>
                        <input  autoComplete="off" className='form-input' value = {pais} onChange={(e)=> setPais(e.target.value)}></input>
                    </div>     
    
                  
                    <div className="input-container" >
                        <h2>Código Postal:</h2>
                        <input autoComplete="off" className='form-input-small'  value = {codigoPostal} onChange={(e)=> setCodigoPostal(e.target.value)}></input>
                    </div>             
                    <div className="input-container">
                        <h2>*Teléfono:</h2>
                        <input      type="text"  className='form-input'  value = {telefono} onChange={(e)=> setTelefono(e.target.value)}></input>
                         <p className={errorMsg3}>Este campo no puede estar en blanco y tiene que ser válido. </p>
                        
                    </div>      
            </div>

            <div className="form-column">
                <div className="input-container">
                    <h2>Servicio:</h2>
                        <div className="button-selector-wrapper">
                            <div className="input-container">
                                <button className={a} onClick={selectA}>Transporte Aéreo</button>
                                <button className={b} onClick={selectB}>Transporte Marítimo</button>
                                <button className={c} onClick={selectC}>Transporte Terrestre</button>
                                <button className={d} onClick={selectD}>Depósito Aduanero </button>
                            </div>
                            <div className="input-container">
                                <button className={e} onClick={selectE}>Revisión de Mercancía</button>
                                <button className={f} onClick={selectF}>Asesoría Aduanal</button>
                                <button className={g} onClick={selectG}>Gestionadores de Pagos</button>
                                <button className={h} onClick={selectH}>Casillero</button>
                            </div>
                           
                        </div>
                    
        
                </div>  
                <div className="input-container">
                        <h2>*Comentario:</h2>
                        <textarea className='form-input-long'  value = {comentario} onChange={(e)=> setComentario(e.target.value)}></textarea>
                        <p className={errorMsg4}>Este campo no puede estar en blanco y tiene que tener al menos 10 caracteres. </p>
                </div>      
                <div className = 'separator50'></div>
                <div className="radial-container">
                    <button className={terms} onClick={radial}> </button>
                   
                    <button className="link" onClick={goPriv}> Acepto las política de privacidad.</button>
                    
            </div>
                <div className="separator50"/>
                <button type="submit" value="Send" className="button-light" onClick = {formVerifier} >Contáctanos</button>
                <div className="separator50"/>
                <p className={errorMsg}>Acepta las políticas de privacidad y rellena todos los campos obligatorios con información válida antes de continuar. </p>
            </div>
            
            </div>
            <div className = 'separator50'></div>

            <div className="separator50"></div>
            <div className="second-half">

                <h3>No utilizaremos tus datos para mandarte publicidad, o molestarte con mensajes no solicitados.</h3>

            </div>
           
           
        </div>
     
        </>
        
        
    )
}
export default Afiliate