import React, { useState } from "react";
import Logo from '../../../Assets/logo-white.svg'
import ig from '../../../Assets/social/Instagram.svg'
import tw from '../../../Assets/social/Twitter.svg'
import li from '../../../Assets/social/LinkedIn.svg'


import './Footer.css'

const Footer = () => {

    //selectable base in page
    //mobile menu
    const goIg = () => {
        window.open('https://www.instagram.com/europeanlogisticsforwarder/?igshid=YjNmNGQ3MDY%3D')
    }
    const goTw = () => {
        window.open('https://twitter.com/envios_elf')

    }

    const goLi= () => {
        window.open('https://www.linkedin.com/company/european-logistics-forwarder-elf/?original_referer=');
 
    }


    return(
        <div className="footer-container">    
            <img className = 'footer-logo' src={Logo}></img>
            <div className = "text"> 
            <p className="white">Todos los derechos reservados - elf 2023</p>
            </div>
            <div className="footer-button-container">
                <button className="footer-button" onClick={goIg}>
                  <img className="social-logo" src={ig}></img>
                </button>
                <button className="footer-button"  onClick={goTw}>
                    <img className="social-logos" src={tw}></img>
                </button>
                <button className="footer-button" onClick={goLi}>
                    <img className="social-logos" src={li}></img>
                </button>
            </div>
        </div>
        
    )
}
export default Footer